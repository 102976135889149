<template>
  <div class="message">
    <watch-head :title="$t('群组/聊天')" :img="require('@/assets/bracelet_img/67.png')" />
    <div class="main">
      <div class="name">{{ groupName }}</div>
      <!-- <div class="input">输入当中...</div> -->
      <div class="input"></div>
      <van-pull-refresh
        class="msg"
        ref="pullRefs"
        v-model="refreshing"
        @refresh="getMessageList">
        <div class="row" v-for="(item, index) in list" :key="index">
          <div v-show="item.type == im_types.MSG_CUSTOM" class="center">
            <div v-show="item.payload.data == 'group_create'">{{ $t('成功创建聊天室') }}</div>
          </div>
          <div v-show="item.type == im_types.MSG_GRP_TIP" class="center">
            <div v-show="item.payload.operationType == 1">
              <span>{{ $t('有成员加群') }}</span>
            </div>
            <div v-show="item.payload.operationType == 2">
              <span>{{ $t('有群成员退群') }}</span>
            </div>
            <div v-show="item.payload.operationType == 3">
              <span>{{ $t('有群成员被踢出群') }}</span>
            </div>
          </div>
          <div v-if="item.type == im_types.MSG_TEXT && item.flow == 'in'" class="left">
            <div class="avatar">
              <img v-if="otherUserInfo(item.from)?.avatar" class="img" :src="otherUserInfo(item.from)?.avatar" alt="">
              <!-- <div class="grade">{{HistoricalUser[1].level_no}}</div> -->
            </div>
            <div class="info">
              <div class="name">{{ otherUserInfo(item.from)?.nickname ?? $t('暂无昵称') }}</div>
              <div class="text">{{item.payload.text}}</div>
            </div>
          </div>
          <div v-if="item.type == im_types.MSG_TEXT && item.flow == 'out'" class="right">
            <div class="text">{{item.payload.text}}</div>
            <div class="avatar">
              <img class="img" :src="userInfo.avatar" alt="">
              <!-- <div class="grade">{{userInfo.level_no}}</div> -->
            </div>
          </div>
        </div>
      </van-pull-refresh>
      <div class="send">
        <van-field class="sendOut" v-model="message" label-width="0" :placeholder="$t('在此输入...')">
          <template #button>
            <van-button :loading="isMsgLoading" @click="sendOut()" :icon="require('@/assets/bracelet_img/68.png')" />
          </template>
        </van-field>
      </div>
    </div>
    <watch-foot />
  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import watchFoot from '@/components/watchFoot/index.vue'

import { getInfoByIds } from '@/api/api'
import { getMessageList, timIsLogin, createMessage, groupChat, IM_TYPES } from '@/utils/tim.js'
export default {
  components: {
    watchHead,
    watchFoot
  },
  data () {
    return {
      im_types: null,
      message: '',
      refreshing: false,
      list: [],
      userInfo: {},
      group: {
        page: 1,
        limit: 20,
        id: '',
      },
      timer: null,
      otherUser: [], // 房间所有成员列表
      userIdList: [], // 成员id列表
      oldUserIdListLength: 0, // 老的成员id列表长度
      isMsgLoading: false,
      nextReqMessageID: null,
      isCompleted: false,
      groupName: '',
    }
  },
  created () {
    this.waitForGlobal()
  },
  computed: {
    otherUserInfo () {
      return (uid) => { // 用于接收计算属性传过来的用户id
        let array = this.otherUser
        for (let index = 0; index < array.length; index++) { // 先遍历已有的成员列表
          const element = array[index];
          if(element.user_id == uid){
            return element // 如果列表有对应的成员直接返回
          }
        }
        return null
      }
    }
  },
  mounted(){
    // 监听消息
    groupChat((data) => {
      if(data.type == this.im_types.MSG_GRP_SYS_NOTICE && (data.payload.operationType == 5 || data.payload.operationType == 4)){ // 当前房间解散 或者被踢出群
        this.$router.replace({path: '/roomHome'})
      }else{
        if(this.$route.path == '/groupChat'){
          this.list.push(data)
          this.screenImArr([data])
          let timer = setTimeout(() => {
            clearTimeout(timer)
            this.$refs.pullRefs.$el.scrollTop = 999999
          }, 100)
        }
      }
    })
  },
  methods: {
    // 获取指定用户信息
    async getOtherInfoBy(){
      try {
        let res = await getInfoByIds({ uid: this.userIdList + '' })
        if(res.code == 0){
          this.otherUser = res.data
        }
      } catch (error) {
        return null
      }
    },
    // 筛选im记录数组
    screenImArr(array){
      this.oldUserIdListLength = this.userIdList.length // 将之前的userIdList长度保存
      for (let index = 0; index < array.length; index++) { // 遍历im聊天记录
        const element = array[index]; // 当前im聊天记录对象
        let isExist = this.userIdList.find(item => { // 判断当前im记录的用户id是否存在userIdList数组中
          return item == element.from;
        });
        if(!isExist && !element.isSystemMessage && element.from != this.userInfo.id && element.type != this.im_types.MSG_GRP_TIP){
          // im聊天记录的用户id不存在userIdList列表中 同时不是SYSTEM(系统)会话 同时还不包括用户自己 也不能是群提示消息
          this.userIdList.push(element.from)// 将当前聊天记录的用户id添加到数组
        }
      }
      if(this.oldUserIdListLength != this.userIdList.length){
        // 前后两次数组长度不一致 证明有新用户需要查询昵称头像
        this.getOtherInfoBy()
      }
    },
    //im获取历史消息
    getMessageList(){
      if (this.isCompleted || this.nextReqMessageID == -1) {
        this.$toast(this.$t('没有更多了'))
        this.refreshing = false
        return
      }
      let params = {
        conversationID: `GROUP${this.group.id}`,
        nextReqMessageID: this.nextReqMessageID
      }
      getMessageList(params).then(res => {
        if (res.code == 0) {
          this.nextReqMessageID = res.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
          this.isCompleted = res.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
          let array = res.data.messageList // 聊天记录
          console.log('历史聊天记录-----',array,this.nextReqMessageID,this.isCompleted);

          this.screenImArr(array)
          
          this.list = [].concat(array, this.list)
          if (this.group.page == 1) {
            // 第一页的历史记录直接滚动到最底部
            let timer = setTimeout(() => {
              clearTimeout(timer)
              this.$refs.pullRefs.$el.scrollTop = 999999
            }, 100)
          } else {
            // 下一页的历史记录获取完直接滚动到上一页的位置
            let timer = setTimeout(() => {
              clearTimeout(timer)
              this.$refs.pullRefs.$el.scrollTop = (-(this.$refs.pullRefs.$el.scrollHeight - (this.$refs.pullRefs.$el.scrollHeight + this.$refs.pullRefs.$el.scrollTop + this.$refs.pullRefs.$el.clientHeight))) + this.$refs.pullRefs.$el.clientHeight
            }, 100)
          }
          this.group.page++
          this.refreshing = false
        }
      }).catch(err => {
        console.warn('有这个err嘛-------',err);
        if(err.code == 10010 || err.code == 10015 || err.code == 10007){// 群组不存在，或者曾经存在过，但是目前已经被解散。
          this.$router.replace({path: '/roomHome'})
        }
      })
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo') && timIsLogin) {
        window.clearTimeout(this.timer);
        this.groupName = this.$route.query.groupName
        this.group.id = this.$route.query.groupId
        this.getUserInfo()
        this.im_types = IM_TYPES
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    /* 获取当前用户信息 */
    getUserInfo () {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

      this.group.page = 1
      this.isCompleted = false
      this.nextReqMessageID = null
      this.list = []
      this.getMessageList()
    },

    /* 发送消息 */
    sendOut () {
      if (this.message.length == 0) {
        this.$toast(this.$t('发送消息不能为空'))
        return
      }
      if(!this.group.id){
        this.$toast(this.$t('请先选择房间'))
        return
      }
      this.isMsgLoading = true
      let params = {
        type: 'GROUP',
        id: this.group.id,
        text: this.message
      }
      createMessage(params).then((res) => {
        // 发送成功
        if (res.code == 0) {
          this.list.push(res.data.message)
          let timer = setTimeout(() => {
            clearTimeout(timer)
            this.$refs.pullRefs.$el.scrollTop = 999999
          }, 100)
          if (res.data.message.from == this.userInfo.id) {
            this.message = ''
          }
        }
        this.isMsgLoading = false
      }).catch((err) => {
        this.isMsgLoading = false
        // 发送失败
        console.warn('sendMessage error:', err);
        if(err.code == 10010 || err.code == 10015 || err.code == 10007){ // 群组不存在，或者曾经存在过，但是目前已经被解散。
          this.$router.replace({path: '/roomHome'})
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.message {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 80px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .main {
    margin-top: 30px;
    padding: 0 33px;
    .name {
      font-family: 'AeroMaticsBold';
      font-size: 29px;
    }
    .input {
      font-family: 'TsangerYuYangT-W05';
      font-size: 12px;
      margin: -2px 0 4px;
    }
    .msg {
      width: 100%;
      height: 457px;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      padding: 12px;
      overflow-y: scroll;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .row {
        margin-bottom: 10px;
        .center{
          text-align: center;
          font-size: 10px;
          color: #969799;
        }
        .left {
          display: flex;
          align-items: center;
          .avatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-color: #2b2b2b;
            border: 3px solid #2b2b2b;
            border-radius: 50%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .grade {
              position: absolute;
              right: -10px;
              bottom: -2px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #2b2b2b;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .info{
            margin-left: 8px;
            .name{
              font-size: 10px;
              margin-bottom: 4px;
            }
            .text {
              background-color: #ffffff;
              border-radius: 15px;
              padding: 3px 10px;
              font-family: 'AeroMaticsItalic';
              font-size: 12px;
              color: #2b2b2b;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .avatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-color: #2b2b2b;
            border: 3px solid #2b2b2b;
            border-radius: 50%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .grade {
              position: absolute;
              right: -10px;
              bottom: -2px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #2b2b2b;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .text {
            background-color: #ffffff;
            border-radius: 15px;
            padding: 8px 14px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            color: #2b2b2b;
            margin-right: 8px;
          }
        }
      }
    }
    .msg::-webkit-scrollbar {
      display: none;
    }
    .send {
      margin-top: 2px;
      .van-field {
        background-color: #ffffff;
        border-radius: 8px;
        // padding: 2px 7px;
        /deep/.van-field__right-icon {
          display: flex;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
        /deep/.xia {
          width: 20px;
          height: 20px;
        }
      }
      .sendOut {
        /deep/.van-field__control {
          font-family: 'TsangerYuYangT-W03';
          font-size: 15px;
          color: #2b2b2b;
        }
        /* WebKit, Blink, Edge */
        /deep/.van-field__control::-webkit-input-placeholder {
          font-size: 15px;
        }
        /* Mozilla Firefox 4 to 18 */
        /deep/.van-field__control:-moz-placeholder {
          font-size: 15px;
        }
        /* Mozilla Firefox 19+ */
        /deep/.van-field__control::-moz-placeholder {
          font-size: 15px;
        }
        /* Internet Explorer 10-11 */
        /deep/.van-field__control:-ms-input-placeholder {
          font-size: 15px;
        }
        /* Microsoft Edge */
        /deep/.van-field__control::-ms-input-placeholder {
          font-size: 15px;
        }
        /deep/.van-field__button{
          line-height: initial;
          display: flex;
        }
        .van-button{
          border: none;
          padding: 0;
          height: auto;
          .van-icon__image{
            width: 20px;
            height: 20px;
          }
        }
        .van-button::before{
          background-color: transparent !important;
        }
      }
    }
  }
}
</style>